
<template>
    <v-row dense>
    <v-col cols="12">
      <v-alert
              dense
              class="form1"
              color="#FAFF00"
              border="bottom"
              colored-border>
              <v-sheet
              class="rounded"
              elevation="6"
              v-show="loading == true"
              color="gray lighten-2"
              style="height:600px !important"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  max-height="600px"
                  type="image,image,image"
                ></v-skeleton-loader>
              </v-sheet>
              <v-carousel
                v-show="loading == false"
                cycle
                :show-arrows="false"
                height="600px"
                hide-delimiter-background
              >
                <v-carousel-item>
                  <v-sheet
                  height="100%">
                  <v-btn @click="Edit" v-show="logged.level == 3 || logged.level == 4" icon style="position:absolute;top:0;right:0;"><v-icon color="#43658B">mdi-pencil</v-icon></v-btn>
                    <div class="pa-2" v-html="html"/>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
              </v-alert>
            </v-col>
    </v-row>
    </template>
    <script>
    import API from '@/services/http'
    import Vue from 'vue';
    export default {
      data(){
        return{
          data:[],
          html:null,
          logged:JSON.parse(localStorage.getItem('logged')),
          loading:true,
          // height:(window.innerHeight-)+'px'
        }
      },
      mounted(){
        this.Getdata()
        Vue.prototype.$LoadingKetentuan = this.loadingx
      },
      methods:{
        Getdata(){
          API.get('custom').then(x=>{
            this.html = x.data.find(s => s.type == 'SK').html
            this.data = x.data
            console.log(this.data)
          })
        },
        Edit(){
          this.$router.push('/FormSyaratKetentuan')
        },
        loadingx(){
          this.loading = !this.loading
        }
      }
    }
    </script>
    <style>
    .syarat{
      color: black !important;
    }
    .tebal{
      color: #a10115 !important;
    }
    .number{
    color: black !important;
    font-size: 22px !important;
  }
    </style>